<template>
  <header class="header">
    <a href="https://bartik.pl">
      <figure class="logo">
        <img
          class="logo__image"
          src="https://bartik.pl/wp-content/uploads/2022/04/logo.png"
          alt="Bartilogo"
        />
      </figure>
    </a>
  </header>

  <main class="main">
    <h2 class="main__title">Wyszukaj Zlecenie</h2>
    <h3 v-if="error" class="form__error">Nieprawidłowy numer zlecenia</h3>
    <div class="form">
      <div class="form__column">
        <input
          class="form__input"
          type="text"
          @keypress="isLetter($event)"
          v-model="orderNo"
          @click="clear"
          @keyup="clear"
          @keyup.enter="fileCheck(siteUrl + orderNo + '.zip')"
        />
      </div>
      <button
        class="form__submit"
        v-if="!fileExists"
        @click="fileCheck(siteUrl + orderNo + '.zip')"
      >
        Sprawdź
      </button>
      <a
        ref="fileLink"
        @click="clear"
        :href="siteUrl + orderNo + '.zip'"
        class="form__submit--none"
        >Pobierz</a
      >
    </div>
  </main>
  <footer class="footer">
    <p class="footer__paragraph">Made by MK&copy; 2022</p>
  </footer>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      siteUrl: "/orders/",
      orderNo: "",
      error: false,
      fileExists: false,
    };
  },
  methods: {
    fileCheck(urlToFile) {
      var xhr = new XMLHttpRequest();
      xhr.open("HEAD", urlToFile, false);
      xhr.send();
      console.log(xhr.status);

      if (xhr.status == "404") {
        this.fileExists = false;
        this.error = true;
      } else {
        this.fileExists = true;
        this.$refs.fileLink.click();
      }
      console.log(this.fileExists);
    },

    isLetter(e) {
      var keyCode = e.which;

      if (keyCode === 46) {
        return true;
      }

      if ((keyCode !== 8 || keyCode === 32) && (keyCode < 48 || keyCode > 57)) {
        e.preventDefault();
      }
    },
    clear() {
      this.error = false;
      this.fileExists = false;
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");

* {
  box-sizing: border-box;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  padding: 0;
  margin: 0;
  background-color: #000;
  color: white;

  font-family: "Ubuntu";
  line-height: normal;
  text-align: center;
  mix-blend-mode: normal;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.page__title {
  font-weight: 100;
  font-size: 60px;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.logo__image {
  width: 37.5%;
  object-fit: cover;
}

.main__title {
  font-weight: normal;
  font-size: 24px;
}

.form {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  &__column {
    display: flex;
    flex-direction: column-reverse;
  }
  &__input {
    border-radius: 9px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

    font-weight: 300;
    font-size: 16px;
    text-indent: 10px;
    color: #000;

    width: 250px;
    height: 48px;
    margin-right: 50px;
    transition: 0.1s;

    &:focus {
      border: 2px solid #232323;
    }

    &:focus:invalid {
      border: 2px solid #fffdfd;
    }

    &:focus:invalid + .form__label {
      visibility: visible;
    }
  }

  &__submit {
    background: #000;
    border-radius: 9px;
    text-decoration: none;

    font-weight: bold;
    font-size: 16px;
    text-align: center;
    border: solid #fff 1px;

    color: #ffffff;
    width: 250px;
    padding: 1em;

    transition: 0.3s;
    &:hover {
      border-color: initial;
      cursor: pointer;
      box-shadow: 0px 5px 10px rgba(255, 255, 255, 0.5);
    }

    &--none {
      display: none;
    }
  }
}

.footer {
  text-align: center;
  font-size: 24px;
  &__paragraph {
    margin: 0;
  }
}

@media all and (max-width: 1000px) {
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@media all and (max-width: 500px) {
  .page__title {
    font-size: 30px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__input {
      margin: 0;
      margin-bottom: 10px;
    }
  }

  .footer {
    font-size: 16px;
  }
}

@media all and (max-height: 500px) {
  .logo {
    display: none;
  }
}
</style>
